import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import ServingSizeForm from './components/ServingSizeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  HTTP_STATUS,
  ADD_TEXT,
  servingSizeInitialValue,
  EDIT_SERVING_SIZE_TEXT,
  ADD_SERVING_SIZE_TEXT,
  SERVING_SIZE_IS_UPDATED_TEXT,
  SERVING_SIZE_IS_CREATED_TEXT,
} from 'constants/index';
import { AddServingSizeFormType, ParamType } from 'interfaces';
import { updateServingSizeTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ServingSizePayload,
  useCreateServingSizeMutation,
  useGetServingSizeLazyQuery,
  useUpdateServingSizeMutation,
} from 'generated/graphql';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const servingSizeMethods = useForm<AddServingSizeFormType>({
    defaultValues: servingSizeInitialValue,
    resolver: yupResolver(updateServingSizeTypeSchema),
  });

  const { handleSubmit: handleServingSizeSubmit, setValue: setServingSizeValue } = servingSizeMethods;

  const [updateServingSize, { loading: productTypeLoading }] = useUpdateServingSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateServingSize } = data;
      const { response } = updateServingSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [createServingSize, { loading: createLoading }] = useCreateServingSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createServingSize } = data;
      const { response } = createServingSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getServingSize, { loading: getLoading }] = useGetServingSizeLazyQuery({
    onCompleted: (data) => {
      const { getServingSize } = data || {};
      const { response, servingSize } = getServingSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(servingSize as ServingSizePayload['servingSize']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onServingSizeSubmit: SubmitHandler<AddServingSizeFormType> = async (data) => {
    const { size } = data || {};

    if (id) {
      await updateServingSize({
        variables: {
          updateServingSizeInput: {
            id,
            size: Number(size),
          },
        },
      });
    } else {
      await createServingSize({
        variables: {
          createServingSizeInput: {
            size: Number(size),
          },
        },
      });
    }
  };

  const fetchServingSize = useCallback(async () => {
    id &&
      (await getServingSize({
        variables: {
          getServingSizeInput: {
            id,
          },
        },
      }));
  }, [id, getServingSize]);

  const setValues = (params: ServingSizePayload['servingSize']) => {
    const { size } = params || {};

    setServingSizeValue('size', `${size}`);
  };

  useEffect(() => {
    id && fetchServingSize();
  }, [id, fetchServingSize]);

  const loading = productTypeLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...servingSizeMethods}>
        <form onSubmit={handleServingSizeSubmit(onServingSizeSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? EDIT_SERVING_SIZE_TEXT : ADD_SERVING_SIZE_TEXT}</Typography>
            {!id && (
              <Button type="submit" variant="contained" size="small">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader rows={1} columns={12} />
              ) : (
                <ServingSizeForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BackdropLoader
        open={loading}
        text={id ? SERVING_SIZE_IS_UPDATED_TEXT : SERVING_SIZE_IS_CREATED_TEXT}
      />
    </Fragment>
  );
};

export default Edit;
