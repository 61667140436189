// imports
import { Controller, useFormContext } from 'react-hook-form';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, Chip, CircularProgress, TextField, Typography } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces, constants, helper
import { formatValue, renderServingSize } from 'lib/helper';
import { useDebounce } from 'hooks/useDebounce';
import { SelectType, ServingSizeSelectorProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import {
  ServingSizesPayload,
  useFindAllServingSizeSelectorLazyQuery,
  SupplierStatus,
} from 'generated/graphql';

const ServingSizeSelector: FC<ServingSizeSelectorProps> = ({
  name,
  title,
  disabled,
  isRequired,
  defaultValue,
  handleChange,
  margin = 'dense',
  isMultiple = false,
  isClearable = true,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ServingSizesPayload['data']>([]);
  const debounceValue = useDebounce(searchQuery);

  const { control } = useFormContext();

  const [findAllServingSize, { loading }] = useFindAllServingSizeSelectorLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllServingSize } = data;
      const { data: servingSize, response, pagination } = findAllServingSize || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((servingSize as ServingSizesPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setGetLoading(false);
      setOptions([]);
    },
  });

  const fetchServingSizes = useCallback(
    async (page?: number, search?: string) => {
      if (!page) setGetLoading(true);
      await findAllServingSize({
        variables: {
          findAllServingSizesInput: {
            paginationOptions: { page: page || 1, limit: 10 },
            ...(search && { search }),
          },
        },
      });
    },
    [findAllServingSize],
  );

  useEffect(() => {
    fetchServingSizes();
  }, [fetchServingSizes]);

  const lastElementRef = (node: HTMLLIElement) => {
    if (loading) return;
    if (observer?.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prev) => {
          const newPage = prev + 1;
          fetchServingSizes(newPage, searchQuery);
          return newPage;
        });
      }
    });
    if (node) observer.current.observe(node);
  };

  const onSearch = useCallback(
    async (val: string) => {
      try {
        setPage(1);
        setOptions([]);
        await fetchServingSizes(1, val);
      } catch (error) {
        console.log('error', error);
      }
    },
    [fetchServingSizes],
  );

  useEffect(() => {
    debounceValue && onSearch(debounceValue);
  }, [debounceValue, onSearch]);

  const onClose = () => {
    setPage(1);
    setOptions([]);
    setSearchQuery('');
    fetchServingSizes(1);
  };

  const updatedOptions = useMemo(() => {
    if (options?.length) {
      return renderServingSize(options ?? []);
    }
    return [];
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Autocomplete
            ref={ref}
            multiple={isMultiple}
            id={name}
            onBlur={onBlur}
            disableClearable={!isClearable}
            disabled={disabled}
            options={updatedOptions}
            onClose={onClose}
            loading={loading}
            value={isMultiple ? (value as SelectType[]) : (value as SelectType)}
            onChange={(_, value) => {
              const selectedValue = isMultiple ? value ?? [] : value ?? selectTypeInitialValues;
              onChange(selectedValue);
              handleChange && handleChange(selectedValue);
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={({ value: option }, { value }) => value === option}
            renderOption={(props, option) => {
              const { name, isActive, value: val } = option;
              return (
                <Box
                  component="li"
                  sx={{ '& > div': { width: '100%' } }}
                  key={val}
                  ref={lastElementRef}
                  {...props}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="caption">{name || '--'}</Typography>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={formatValue(isActive ? SupplierStatus.Active : SupplierStatus.Inactive)}
                      color={isActive ? 'success' : 'error'}
                    />
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                margin={margin}
                variant="outlined"
                helperText={message}
                error={Boolean(error)}
                defaultValue={defaultValue}
                label={isRequired ? `${title} *` : title}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

export default ServingSizeSelector;
