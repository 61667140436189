import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment, useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import { ArrowIcon } from 'assets/svgs';
import StepForm from '../Steps/StepForm';
import { Alert } from 'components/common/Alert';
import AutoSaveDialogBox from 'components/common/DialogBox';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SaveAsDraftButton from 'components/common/SaveAsDraftButton';
import SaveAsDraftDialogBox from 'components/common/SaveAsDraftDialogBox';
// constant, svgs, styles, context, reducer, helper
import {
  BY_TEXT,
  SAVE_TEXT,
  BACK_TEXT,
  DOSAGE_TEXT,
  HTTP_STATUS,
  INGREDIENTS_TEXT,
  CREATE_FORMULA_TEXT,
  FORMULA_IS_CREATING_TEXT,
  addNewFormulaInitialValue,
  dosageFormulationFormInitialValues,
} from 'constants/index';
import {
  VeganType,
  FactBoxType,
  CoMfgCostType,
  BottleCapType,
  ProteinBaseType,
  FormulaBlendInput,
  TabletCoatingColor,
  SweetenerNatureType,
  MiniTabFormulationType,
  ProductFormulationTypes,
  useCreateFormulaMutation,
  useSaveFormulaAsDraftMutation,
} from 'generated/graphql';
import useCustomBlocker from 'hooks/useCustomBlocker';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { addFormulaSchema, formulationSchema } from 'validationSchema';
import { StickyTopHeader, flexCenterBetween } from 'styles/commonComponentStyle';
import { AddNewFormulaFormType, DosageFormulationFormType, SaveFormulaAsDraftFormType } from 'interfaces';

const AddFormula: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(FormulaContext);
  const { ingredientsBlend, productFormulationType, formulation, activeStep } = state || {};
  const { type } = productFormulationType || {};
  // local state
  const [isBlocker, setIsBlocker] = useState(true);
  const [isOpenDraft, setIsOpenDraft] = useState(false);

  const methods = useForm<DosageFormulationFormType>({
    resolver: yupResolver(formulationSchema(type as ProductFormulationTypes)),
    defaultValues: dosageFormulationFormInitialValues,
  });

  const formulaMethods = useForm<AddNewFormulaFormType>({
    resolver: yupResolver(addFormulaSchema),
    defaultValues: addNewFormulaInitialValue,
    mode: 'all',
  });

  const { handleSubmit, formState, getValues } = methods;
  const { isDirty } = formState;

  const {
    handleSubmit: handleFormulaSubmit,
    formState: formulaFormState,
    getValues: getFormulaValues,
  } = formulaMethods;

  const { isDirty: isFormulaFormDirty } = formulaFormState;

  const blocker = useCustomBlocker(isDirty, isFormulaFormDirty, isBlocker);

  const [createFormula, { loading: createFormulaLoading }] = useCreateFormulaMutation({
    onCompleted: (res) => {
      const { createFormula: generateFormula } = res || {};
      const { response } = generateFormula || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      setIsBlocker(true);
      Alert.error(message);
    },
  });

  const [saveFormulaAsDraft, { loading: saveFormulaAsLoading }] = useSaveFormulaAsDraftMutation({
    onCompleted: (res) => {
      const { saveFormulaAsDraft } = res || {};
      const { response } = saveFormulaAsDraft || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        isOpenDraft && navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  // setting payload for formulaBlends

  const formulaBlends = ingredientsBlend?.map<FormulaBlendInput>((item) => {
    const { name: blendName, ingredients } = item;

    const ingredientBlends = formatCalcFormulaIngredients(ingredients);

    return {
      name: blendName,
      formulaIngredients: ingredientBlends,
    };
  });

  const onSave: SubmitHandler<AddNewFormulaFormType> = async (data) => {
    const { formulaIngredients, name } = data;

    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

    const {
      beadlets,
      bottleCap,
      coMfgCostType,
      coMfgCostPercentage,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productSubType,
      productType,
      proteinBase,
      servingPerContainer,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      servingUnit,
      productFormulationType,
      productSize,
      sandCoating,
    } = formulation;

    await createFormula({
      variables: {
        createFormulaInput: {
          name,

          bottleCap: bottleCap as BottleCapType,
          ...(coMfgCostType && { coMfgCostType: coMfgCostType as CoMfgCostType }),
          ...(coMfgCostPercentage && { coMfgCostPercentage: Number(coMfgCostPercentage) }),
          servingUnit,
          servingSize: Number(desiredServingSize),
          servingContainer: Number(servingPerContainer),

          productTypeId: productType,
          productFormulationTypeId: productFormulationType?.value ?? '',

          ...(productSize?.value && { productSizeId: productSize?.value }),

          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(beadlets && { beadletId: beadlets }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(tabletType && { tabletTypeId: tabletType }),
          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(veganType && { veganType: veganType as VeganType }),
          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
        },
      },
    });
    setIsBlocker(false);
  };

  const onSaveAsDraft = () => {
    setIsOpenDraft(true);
    setIsBlocker(false);
  };

  const saveFormulaAsDraftAndContinue: SubmitHandler<SaveFormulaAsDraftFormType> = async (data) => {
    const { name: formulaName } = data;

    const { formulaIngredients } = getFormulaValues();

    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

    const {
      beadlets,
      bottleCap,
      coMfgCostType,
      coMfgCostPercentage,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      proteinBase,
      servingPerContainer,
      servingUnit,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      productType,
      productSubType,
      productSize,
      sandCoating,
    } = getValues();

    await saveFormulaAsDraft({
      variables: {
        saveFormulaAsDraftInput: {
          ...(productSize?.value && { productSizeId: productSize?.value }),

          ...(formulaName && { name: formulaName }),
          ...(servingUnit && { servingUnit: servingUnit }),
          ...(bottleCap && { bottleCap: bottleCap as BottleCapType }),
          ...(coMfgCostType && { coMfgCostType: coMfgCostType as CoMfgCostType }),
          ...(coMfgCostPercentage && { coMfgCostPercentage: Number(coMfgCostPercentage) }),
          ...(desiredServingSize && { servingSize: Number(desiredServingSize) }),
          ...(servingPerContainer && { servingContainer: Number(servingPerContainer) }),

          ...(productType && { productTypeId: productType }),
          ...(productFormulationType?.value && { productFormulationTypeId: productFormulationType?.value }),

          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(beadlets && { beadletId: beadlets }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(tabletType && { tabletTypeId: tabletType }),
          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(veganType && { veganType: veganType as VeganType }),
          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
        },
      },
    });
    isOpenDraft ? setIsOpenDraft(false) : blocker?.proceed && blocker?.proceed();
  };

  const onSubmit: SubmitHandler<DosageFormulationFormType> = (data) => {
    dispatch({ type: ActionType.SET_FORMULATION, formulation: data });
    dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep + 1 });
  };

  const onClose = () => {
    setIsOpenDraft(false);
    setIsBlocker(true);
  };

  const { name: formulaNameInForm } = getFormulaValues();

  const loading = saveFormulaAsLoading || createFormulaLoading;

  return (
    <Fragment>
      <AutoSaveDialogBox
        cancelNavigation={blocker?.reset}
        confirmNavigation={blocker?.proceed}
        showDialog={blocker.state === 'blocked' || isOpenDraft}
        saveAndLeaveNavigation={saveFormulaAsDraftAndContinue}
      />

      <SaveAsDraftDialogBox
        onClose={onClose}
        open={isOpenDraft}
        formulaName={formulaNameInForm}
        onSave={saveFormulaAsDraftAndContinue}
      />

      <BackdropLoader open={loading} text={FORMULA_IS_CREATING_TEXT} />
      <FormProvider {...methods}>
        <Box sx={[flexCenterBetween, StickyTopHeader]}>
          <Typography variant="h5">
            {CREATE_FORMULA_TEXT} {BY_TEXT} {DOSAGE_TEXT}
          </Typography>

          <Box pt={{ lg: 0, xs: 3 }}>
            {activeStep === 1 ? (
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep - 1 });
                  }}>
                  {BACK_TEXT}
                </Button>
                <Box>
                  <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleFormulaSubmit(onSave)}>
                    {SAVE_TEXT}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Fragment>
                {isDirty && <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />}
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowIcon />}
                  onClick={handleSubmit(onSubmit)}>
                  {`Choose ${INGREDIENTS_TEXT}`}
                </Button>
              </Fragment>
            )}
          </Box>
        </Box>
        <StepForm formulaMethods={formulaMethods} activeStep={activeStep} />
      </FormProvider>
    </Fragment>
  );
};

export default AddFormula;
