import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
// components
import ServingSizeTable from './ServingSizeTable';
// constant, styles
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_SERVING_SIZE_ROUTE, SERVING_COUNT_TEXT } from 'constants/index';

const ServingSize: FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SERVING_COUNT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(ADD_SERVING_SIZE_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <ServingSizeTable />
    </Fragment>
  );
};

export default ServingSize;
