import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, SyntheticEvent, useCallback, useEffect, useReducer } from 'react';
// components
import TableLoader from 'components/common/TableLoader';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constants, reducers, graphql, styles
import { useDebounce } from 'hooks/useDebounce';
import {
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  TABS,
  TabEnum,
  LOADING_TABLE_ROWS,
  SERVING_SIZE_TABLE_HEADER,
  EDIT_SERVING_SIZE_ROUTE,
} from 'constants/index';
import {
  ServingSizesPayload,
  useUpdateServingSizeMutation,
  useFindAllServingSizeLazyQuery,
} from 'generated/graphql';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, initialState, servingSizeReducer } from 'reducer/servingSizeReducer';

const ServingSizeTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(servingSizeReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab } = state;

  const debounceValue = useDebounce(search);

  const [findAllServingSize, { loading: fetchLoading, error }] = useFindAllServingSizeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllServingSize } = data;
      const { pagination, response, data: servingSizeData } = findAllServingSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: servingSizeData as ServingSizesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateServingSizeStatus, { loading: updateLoading }] = useUpdateServingSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateServingSize } = data || {};
      const { response } = updateServingSize || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchServingSize();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchServingSize = useCallback(async () => {
    await findAllServingSize({
      variables: {
        findAllServingSizesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search: debounceValue,
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [findAllServingSize, debounceValue, rowsPerPage, page, activeTab]);

  useEffect(() => {
    (!search.length || search.length) && fetchServingSize();
  }, [fetchServingSize, search.length]);

  const tabHandler = (_: SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateServingSizeStatus({
      variables: {
        updateServingSizeInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <TableSearchField
        search={search}
        onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
        onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
      />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={SERVING_SIZE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={3} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, size, isActive } = cell || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{size ?? '--'}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_SERVING_SIZE_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default ServingSizeTable;
