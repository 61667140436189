// imports
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import SweetenerComponentSelect from 'components/common/simpleSelectors/SweetenerComponent';
// interfaces , constants , helper
import { SweetenerComponentSelectorProps } from 'interfaces';

const SweetenerComponentSelector: FC<SweetenerComponentSelectorProps> = ({
  name,
  title,
  handleChange,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value } = field;
        const { error } = fieldState;
        const { message } = error || {};

        return (
          <SweetenerComponentSelect
            name={name}
            title={title}
            value={value}
            onBlur={onBlur}
            helperText={message}
            error={Boolean(message)}
            handleChange={(val) => {
              onChange(val);
              handleChange && handleChange(val);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};

export default SweetenerComponentSelector;
