// imports
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces, constants, helper
import { renderSandCoating } from 'lib/helper';
import { SandCoatingSelectProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import { SandCoatingsPayload, useFindAllSandCoatingLazyQuery } from 'generated/graphql';
import { useDebounce } from 'hooks/useDebounce';

const SandCoatingSelect: FC<SandCoatingSelectProps> = ({
  name,
  title,
  value,
  isActive,
  handleChange,
  margin = 'dense',
  isClearable = false,
}) => {
  const [options, setOptions] = useState<SandCoatingsPayload['data']>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debounceValue = useDebounce(searchQuery);

  const updatedOptions = [...renderSandCoating(options ?? [])];

  const [findAllSandCoating, { loading }] = useFindAllSandCoatingLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSandCoating } = data;
      const { data: sandCoatings, response } = findAllSandCoating || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(sandCoatings as SandCoatingsPayload['data']);
      }
    },
    onError: () => {
      setOptions([]);
    },
  });

  const fetchSandCoating = useCallback(async () => {
    await findAllSandCoating({
      variables: {
        findAllSandCoatingInput: {
          paginationOptions: { page: 1, limit: 10 },
          ...(debounceValue && { search: debounceValue }),
          ...(typeof isActive === 'boolean' && { isActive }),
        },
      },
    });
  }, [findAllSandCoating, isActive, debounceValue]);

  useEffect(() => {
    fetchSandCoating();
  }, [fetchSandCoating]);

  return (
    <Autocomplete
      disableClearable={!isClearable}
      options={updatedOptions}
      value={value}
      onChange={(_, val) => {
        handleChange(val ?? selectTypeInitialValues);
      }}
      onClose={() => setSearchQuery('')}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={({ value: option }, { value: val }) => val === option}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          margin={margin}
          fullWidth
          variant="outlined"
          label={title}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}>
          {updatedOptions?.map((item) => {
            const { name, value } = item;
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default SandCoatingSelect;
