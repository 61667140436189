// imports
import { FC, useCallback, useMemo, useState } from 'react';
// components
import InfiniteSelect from 'components/common/simpleSelectors/InfiniteSelect';
// interfaces , constants , helper
import { HTTP_STATUS } from 'constants/index';
import { SelectType, SweetenerComponentSelectProps } from 'interfaces';
import { SweetenerComponentsPayload, useFetchSweetenerComponentSelectorLazyQuery } from 'generated/graphql';

const SweetenerComponentSelect: FC<SweetenerComponentSelectProps> = ({
  name,
  title,
  value,
  isActive,
  handleChange,
  ...rest
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SweetenerComponentsPayload['data']>([]);

  const [findAllSweetenersComponent, { loading }] = useFetchSweetenerComponentSelectorLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSweetenersComponent } = data;
      const { data: excipients, response, pagination } = findAllSweetenersComponent || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((excipients as SweetenerComponentsPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },

    onError: () => {
      setGetLoading(false);
      setOptions([]);
    },
  });

  const fetchData = useCallback(
    async (page?: number, search?: string) => {
      if (!page) setGetLoading(true);
      await findAllSweetenersComponent({
        variables: {
          findAllSweetenerComponentsInput: {
            paginationOptions: { page: page || 1, limit: 10 },
            ...(search && { search }),
            ...(typeof isActive === 'boolean' && { isActive }),
          },
        },
      });
    },
    [findAllSweetenersComponent, isActive],
  );

  const updatedOptions = useMemo<SelectType[]>(() => {
    if (options?.length) {
      return options?.map<SelectType>((item) => {
        const { id: value, name, isActive } = item || {};

        return {
          value,
          name: name || '',
          isActive: Boolean(isActive),
        };
      });
    }
    return [];
  }, [options]);

  return (
    <InfiniteSelect
      name={name}
      title={title}
      value={value}
      loading={loading}
      fetchData={fetchData}
      setOptions={setOptions}
      totalPages={totalPages}
      getLoading={getLoading}
      options={updatedOptions}
      handleChange={handleChange}
      {...rest}
    />
  );
};

export default SweetenerComponentSelect;
