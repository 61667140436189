import { tabClasses } from '@mui/material/Tab';
import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { listItemTextClasses } from '@mui/material/ListItemText';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { snackbarContentClasses } from '@mui/material/SnackbarContent';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { checkboxClasses } from '@mui/material/Checkbox';
// constants
import { PRIMARY_FONT } from 'constants/fonts';
// theme
import { ThemeOptions } from '@mui/material';

const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: ({ palette, spacing }) => ({
      html: {
        WebkitFontSmoothing: 'auto',
      },

      '*': {
        boxSizing: 'border-box',

        '&:focus-visible': {
          outline: spacing(0),
        },

        '&::-webkit-scrollbar': {
          width: spacing(0.6),
          height: spacing(1),
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: spacing(2.5),
          backgroundColor: palette.primary.main,
        },
      },

      'a:active': {
        textDecoration: 'none',
      },

      a: {
        textDecoration: 'none',
      },

      body: {
        margin: spacing(0),
        padding: spacing(0),
        backgroundColor: palette.secondary.main,
      },

      [`& .${snackbarContentClasses.root}`]: {
        flexWrap: 'initial !important',
        justifyContent: 'space-between !important',
        padding: `${spacing(0.5, 1.2)} !important`,
      },

      [`& .${snackbarContentClasses.action}`]: {
        margin: `${spacing(0)} !important`,
        padding: `${spacing(0)} !important`,
      },
    }),
  },

  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: `${theme.palette.secondary.main} !important`,
      }),
    },
  },

  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: theme.spacing(0),
      }),
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(0),
      }),

      label: ({ theme }) => ({
        fontWeight: 400,
        paddingRight: 10,
        wordBreak: 'break-all',
        fontSize: theme.spacing(1.75),
        color: theme.palette.common.white,
      }),
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1),

        '&:hover': {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.customCommonColor.dark,
          },
        },
      }),

      input: ({ theme }) => ({
        fontWeight: 400,
        fontFamily: PRIMARY_FONT,
        fontSize: theme.spacing(2),
        color: theme.palette.customBlackColor.light,

        '&::placeholder': {
          opacity: 1,
          color: theme.palette.customGrayColor.main,
        },

        ':disabled': {
          cursor: 'not-allowed',
        },

        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          WebkitBoxShadow: `0 0 0 1000px ${theme.palette.secondary.main} inset !important`,
        },
      }),

      sizeSmall: ({ theme }) => ({
        height: theme.spacing(5),
      }),

      notchedOutline: ({ theme }) => ({
        borderColor: `${theme.palette.customCommonColor.dark} !important`,
      }),
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: '78%',
        fontWeight: 400,
        fontSize: theme.spacing(2),
        color: theme.palette.customGrayColor.main,
        backgroundColor: 'transparent !important',
      }),

      shrink: ({ theme }) => ({
        fontSize: theme.spacing(1.5),
        backgroundColor: 'transparent !important',
        color: theme.palette.customGrayColor.main,
        transform: `translate(${theme.spacing(1.75)}, ${theme.spacing(-0.75)}) scale(0.75)`,
      }),
    },
  },

  MuiDialog: {
    styleOverrides: {
      scrollPaper: ({ theme }) => ({
        backdropFilter: `blur(${theme.spacing(0.125)})`,
        background: theme.palette.customCommonColor.light,
      }),

      paper: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        background: theme.palette.customBlackColor.dark,
        boxShadow: `${theme.spacing(-5)} ${theme.spacing(5)} ${theme.spacing(10)} ${theme.spacing(-1)} ${
          theme.palette.customGrayColor.contrastText
        }`,
      }),

      paperWidthSm: ({ theme }) => ({
        maxWidth: theme.spacing(65.75),
      }),

      paperWidthMd: ({ theme }) => ({
        maxWidth: theme.spacing(90),
      }),
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 3),
      }),
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        maxWidth: '80%',
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 700,
        fontSize: theme.spacing(1.75),
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0.75, 1.625),
        textTransform: 'capitalize' as const,

        [theme.breakpoints.down('xs')]: {
          fontSize: theme.spacing(1.75),
        },
      }),

      startIcon: ({ theme }) => ({
        marginRight: theme.spacing(1.5),
      }),

      endIcon: ({ theme }) => ({
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(0),
      }),

      containedPrimary: ({ theme }) => ({
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(2)} ${
          theme.palette.customCommonColor.contrastText
        }`,

        ':disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        },
      }),

      containedSecondary: ({ theme }) => ({
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.contrastText,

        '&:hover': {
          backgroundColor: theme.palette.secondary.contrastText,
        },
      }),

      containedSizeSmall: ({ theme }) => ({
        height: theme.spacing(4.5),
        fontSize: theme.spacing(1.75),
      }),

      containedSizeLarge: ({ theme }) => ({
        height: theme.spacing(7),
        fontSize: theme.spacing(2),
      }),

      containedInfo: ({ theme }) => ({
        background: theme.palette.customSkyBlueColor.contrastText,
        boxShadow: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(2)} ${
          theme.palette.customBlueColor.light
        }`,
      }),

      outlinedError: ({ theme }) => ({
        color: theme.palette.customRedColor.main,
        border: `1px solid ${theme.palette.customRedColor.main}`,
      }),

      outlinedSecondary: ({ theme }) => ({
        textTransform: 'initial' as const,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.customCommonColor.dark}`,
      }),

      outlinedSizeSmall: ({ theme }) => ({
        height: theme.spacing(4.5),
        fontSize: theme.spacing(1.75),
      }),

      outlinedSizeLarge: ({ theme }) => ({
        height: theme.spacing(7),
        fontSize: theme.spacing(2),
      }),

      text: ({ theme }) => ({
        fontWeight: 700,
        fontSize: theme.spacing(1.625),
        textTransform: 'initial' as const,
      }),

      textInfo: ({ theme }) => ({
        color: theme.palette.common.white,
      }),

      textError: ({ theme }) => ({
        color: theme.palette.customRedColor.main,
      }),
    },
  },

  MuiBadge: {
    styleOverrides: {
      root: {},

      badge: ({ theme }) => ({
        fontWeight: 700,
        minWidth: theme.spacing(3),
        height: theme.spacing(2.75),
        fontSize: theme.spacing(1.5),
        position: 'initial' as const,
        transform: 'initial' as const,
        borderRadius: theme.spacing(0.75),
      }),

      colorPrimary: ({ theme }) => ({
        color: theme.palette.customGrayColor.light,
        backgroundColor: theme.palette.customShadowColor.main,
      }),

      colorSuccess: ({ theme }) => ({
        color: theme.palette.customGreenColor.main,
        backgroundColor: theme.palette.customGreenColor.light,
      }),

      colorError: ({ theme }) => ({
        color: theme.palette.customRedColor.dark,
        backgroundColor: theme.palette.customRedColor.light,
      }),
    },
  },

  MuiTypography: {
    styleOverrides: {},
  },

  MuiMenu: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${menuClasses.list}`]: {
          width: 'auto',
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1.5),
          backgroundColor: theme.palette.secondary.main,
        },

        [`& .${menuItemClasses.root}`]: {
          fontWeight: 400,
          fontSize: theme.spacing(1.75),
          borderRadius: theme.spacing(1),
          color: theme.palette.common.white,

          '&:hover': {
            background: theme.palette.customCommonColor.main,
          },
        },
      }),

      paper: {
        boxShadow: 'none !important',
      },
    },
  },

  MuiPopover: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.spacing(0.75),
        filter: `drop-shadow(${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0.25)} ${
          theme.palette.customGrayColor.dark
        })`,
        boxShadow: `${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(-0.5)} ${
          theme.palette.customGrayColor.dark
        }`,
      }),
    },
  },

  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        background: theme.palette.customBlackColor.dark,
        boxShadow: `${theme.spacing(0)} ${theme.spacing(1.5)} ${theme.spacing(3)} ${theme.spacing(-0.5)} ${
          theme.palette.customBoxShadowColor.contrastText
        },${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0.25)} ${theme.spacing(0)} ${
          theme.palette.customBoxShadowColor.main
        }`,
      }),
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.spacing(1.5),
        color: theme.palette.customRedColor.contrastText,
      }),
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.spacing(0.75),
        filter: `drop-shadow(${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0.25)} ${
          theme.palette.customGrayColor.dark
        })`,
        boxShadow: `${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(-0.5)} ${
          theme.palette.customGrayColor.dark
        }`,
      }),

      hasPopupIcon: ({ theme }) => ({
        [`&.${autocompleteClasses.hasPopupIcon}`]: {
          '& svg path': {
            fill: theme.palette.myCustomColor.main,
          },
        },
      }),

      listbox: ({ theme }) => ({
        padding: theme.spacing(1),
        boxShadow: `${theme.spacing(0)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(-0.5)} ${
          theme.palette.customShadowColor.main
        }`,
      }),

      option: ({ theme }) => ({
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
        borderRadius: theme.spacing(1),
        color: theme.palette.common.white,

        '&:hover': {
          background: theme.palette.myCustomColor.light,
        },
      }),

      tagSizeMedium: ({ theme }) => ({
        fontWeight: 400,
        height: theme.spacing(3.25),
        fontSize: theme.spacing(1.5),
      }),

      clearIndicator: {
        visibility: 'visible',
        width: 20,
        height: 20,

        '& svg': {
          fontSize: 17,
        },
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({}),

      colorSecondary: ({ theme }) => ({
        [`&.${checkboxClasses.checked}`]: {
          '& svg path': {
            color: theme.palette.common.white,
          },
        },
      }),
    },
  },

  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 700,
        border: theme.spacing(0),
        height: theme.spacing(2.75),
        fontSize: theme.spacing(1.5),
        minWidth: theme.spacing(6.625),
        padding: theme.spacing(0.125, 1),
        borderRadius: theme.spacing(0.75),
      }),

      deleteIcon: ({ theme }) => ({
        margin: theme.spacing(0, 0, 0, 0.625),
      }),

      label: ({ theme }) => ({
        padding: theme.spacing(0),
      }),

      colorSuccess: ({ theme }) => ({
        color: theme.palette.customGreenColor.main,
        backgroundColor: theme.palette.customGreenColor.light,
      }),

      colorError: ({ theme }) => ({
        color: theme.palette.customRedColor.dark,
        backgroundColor: theme.palette.customRedColor.light,
      }),

      colorPrimary: ({ theme }) => ({
        color: theme.palette.bluePaletteColor.dark,
        backgroundColor: theme.palette.bluePaletteColor.light,
      }),

      colorInfo: ({ theme }) => ({
        color: theme.palette.bluePaletteColor.dark,
        backgroundColor: theme.palette.bluePaletteColor.light,
      }),

      sizeMedium: ({ theme }) => ({
        fontSize: theme.spacing(3),
        height: theme.spacing(4.75),
        minWidth: theme.spacing(8.375),
      }),

      sizeSmall: ({ theme }) => ({
        height: theme.spacing(2.75),
        fontSize: theme.spacing(1.5),
        minWidth: theme.spacing(7.875),
      }),
    },
  },

  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        opacity: 1,
        minHeight: theme.spacing(6),
        minWidth: theme.spacing(6.875),
        padding: theme.spacing(1.5, 0),
        marginRight: theme.spacing(4.75),
        textTransform: 'capitalize' as const,

        '&:last-child': {
          marginRight: theme.spacing(0),
        },

        [`&.${tabClasses.selected}`]: {
          fontWeight: 600,
          color: theme.palette.common.white,
        },
      }),
    },
  },

  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.customGrayColor.light,
      }),

      scrollButtons: {
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },

  MuiTable: {
    styleOverrides: {
      root: ({ theme }) => ({
        th: {
          fontWeight: 600,
          whiteSpace: 'nowrap',
          border: theme.spacing(0),
          fontSize: theme.spacing(1.75),
          color: theme.palette.customGrayColor.light,
          backgroundColor: theme.palette.customShadowColor.main,

          '&:first-of-type': {
            borderRadius: theme.spacing(1, 0, 0, 1),
          },

          '&:last-child': {
            borderRadius: theme.spacing(0, 1, 1, 0),
          },
        },

        td: {
          fontWeight: 400,
          border: theme.spacing(0),
          fontSize: theme.spacing(1.75),
          color: theme.palette.common.white,
        },
      }),
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.spacing(1.75),
        color: theme.palette.common.white,
      }),

      toolbar: ({ theme }) => ({
        padding: `${theme.spacing(0, 3)} !important`,

        [theme.breakpoints.down('xs')]: {
          padding: `${theme.spacing(0, 1)} !important`,
        },
      }),

      actions: ({ theme }) => ({
        marginLeft: theme.spacing(1.25),

        '& button': {
          [theme.breakpoints.down('xs')]: {
            marginLeft: 8,
          },
        },
      }),

      selectRoot: ({ theme }) => ({
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
        marginRight: theme.spacing(1),
      }),

      selectLabel: ({ theme }) => ({
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
      }),

      displayedRows: ({ theme }) => ({
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
      }),
    },
  },

  MuiDrawer: {
    styleOverrides: {
      paper: ({ theme }) => ({
        paddingBottom: theme.spacing(5),
        borderRight: `1px dashed ${theme.palette.customBlueColor.contrastText}`,
      }),
    },
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        lineHeight: '24px',
      },

      icon: {
        top: 'calc(50% - 10px)',
      },
    },
  },

  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(1, 1.375),

        [`&.${listItemButtonClasses.selected}`]: {
          [`& .${listItemTextClasses.primary}`]: {
            fontWeight: 600,
            color: theme.palette.primary.main,
          },

          '& svg path': {
            fill: theme.palette.primary.main,
          },
        },
        [`&.${listItemButtonClasses.focusVisible}`]: {
          background: 'transparent',
        },

        ':hover': {
          background: 'transparent',
        },
      }),
    },
  },

  MuiList: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: theme.spacing(30),
      }),
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: theme.spacing(5),
        color: 'red',

        '& svg path': {
          fill: theme.palette.customGrayColor.main,
        },
      }),
    },
  },

  MuiListItemText: {
    styleOverrides: {
      primary: ({ theme }) => ({
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
        textTransform: 'capitalize' as const,
        color: theme.palette.customGrayColor.light,
      }),
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        backgroundImage: 'none',
        backgroundColor: theme.palette.secondary.main,
      }),
    },
  },

  MuiIconButton: {
    styleOverrides: {},
  },

  MuiSvgIcon: {
    styleOverrides: {
      colorSecondary: ({ theme }) => ({
        color: theme.palette.customGrayColor.light,
      }),

      fontSizeSmall: {
        fontSize: '5px',
      },

      fontSizeLarge: {
        fontSize: '20px',
      },
    },
  },

  MuiButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: theme.spacing(4),
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.customCommonColor.dark}`,

        '& button': {
          border: theme.spacing(0),
        },
      }),

      groupedOutlined: ({ theme }) => ({
        width: theme.spacing(4),
        border: '0px !important',
        minWidth: theme.spacing(4),
        fontSize: theme.spacing(1.75),
        borderRadius: theme.spacing(1),
        color: `${theme.palette.customBlackColor.dark} !important`,

        '& svg': {
          fontSize: theme.spacing(2.5),
        },
      }),
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      alternativeLabel: {
        top: 10,
        left: 'calc(-100% + 24px)',
        right: 'calc(100% + 0px)',
      },
      active: ({ theme }) => ({
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.primary.main,
        },
      }),
      completed: ({ theme }) => ({
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.primary.main,
        },
      }),
      line: {
        borderRadius: 1,
        borderTopWidth: 1,
      },
    },
  },

  MuiStep: {
    styleOverrides: {
      alternativeLabel: {
        padding: 0,
        display: 'flex',
      },
    },
  },

  MuiStepIcon: {
    variants: [
      {
        props: {
          completed: true,
        },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '100%',
        }),
      },
    ],
    styleOverrides: {
      text: ({ theme }) => ({
        fill: theme.palette.common.white,
      }),
    },
  },

  MuiDivider: {
    styleOverrides: {
      root: {
        borderWidth: 1,
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.customGrayColor.main,
      }),
    },
  },
};

export default components;
