import { Editor } from 'react-draft-wysiwyg';
import { Modifier, EditorState } from 'draft-js';
import NumbersIcon from '@mui/icons-material/Numbers';
import { Box, Popover, Typography, IconButton } from '@mui/material';
import { FC, JSX, useState, useRef, MouseEvent, forwardRef } from 'react';
// constants
import { SYMBOLS_ARRAY } from 'constants/index';
// interfaces
import { SymbolPopoverProps, TextEditorProps } from 'interfaces';
// css
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SymbolOption = forwardRef<Editor, SymbolPopoverProps>(
  ({ editorState, onEditorStateChange: onChange }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleWidgetClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      if (ref) {
        const { current } = (ref as any) || {};
        const { editor } = current || {};
        const { blur } = editor || {};
        blur();
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSymbolClick = (symbol: string) => {
      const contentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        symbol,
        editorState.getCurrentInlineStyle(),
      );
      const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      onChange(newEditorState);
      handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <IconButton
          onClick={handleWidgetClick}
          color="secondary"
          sx={{ padding: (theme) => theme.spacing(0, 0.625), marginBottom: 0.75, marginX: 0.5 }}>
          <NumbersIcon sx={{ fontSize: 20 }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Box sx={{ p: 2 }}>
            {SYMBOLS_ARRAY.map(({ symbol, title }) => (
              <Typography
                title={title}
                key={symbol}
                onClick={() => handleSymbolClick(symbol)}
                sx={{ cursor: 'pointer', fontFamily: 'Helvetica', fontSize: '24px' }}>
                {symbol}
              </Typography>
            ))}
          </Box>
        </Popover>
      </>
    );
  },
);

const TextEditor: FC<TextEditorProps> = ({ editorState, onEditorStateChange }): JSX.Element => {
  const editorRef = useRef<Editor>(null);

  const onPaste = () => {
    return false;
  };

  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) => palette.customBlackColor.dark,
        padding: ({ spacing }) => spacing(1, 1, 1, 1),
        marginTop: 1,
      }}>
      <Editor
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['italic', 'bold'],
          },
        }}
        ref={editorRef}
        toolbarCustomButtons={[
          <SymbolOption
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            ref={editorRef}
          />,
        ]}
        editorState={editorState}
        editorClassName="text-editor-editor"
        toolbarClassName="text-editor-toolbar"
        onEditorStateChange={onEditorStateChange}
        handlePastedText={onPaste}
      />
    </Box>
  );
};

export default TextEditor;
