import { useNavigate } from 'react-router-dom';
import { FC, Fragment, useContext, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
// components
import { ArrowIcon } from 'assets/svgs';
import StepForm from '../Steps/StepForm';
import { Alert } from 'components/common/Alert';
import DialogBox from 'components/common/DialogBox';
import { BackdropLoader } from 'components/common/BackdropLoader';
// reducer, actions, graphql, constants, schema, context, hooks
import {
  BACK_TEXT,
  SAVE_TEXT,
  HTTP_STATUS,
  INGREDIENTS_TEXT,
  FORMULA_FETCH_TEXT,
  DUPLICATE_FORMULA_TEXT,
  FORMULA_IS_CREATING_TEXT,
  SAVE_AS_DRAFT_TEXT,
} from 'constants/index';
import {
  FormulaBlendInput,
  useCreateFormulaMutation,
  BottleCapType,
  FactBoxType,
  MiniTabFormulationType,
  ProteinBaseType,
  SweetenerNatureType,
  TabletCoatingColor,
  VeganType,
  useSaveFormulaAsDraftMutation,
  CoMfgCostType,
} from 'generated/graphql';
import useCustomBlocker from 'hooks/useCustomBlocker';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  EditFormulaFormProps,
  DosageFormulationFormType,
  AddNewFormulaFormType,
  SaveFormulaAsDraftFormType,
} from 'interfaces';

const DuplicateFormulaForm: FC<EditFormulaFormProps> = ({ methods, formulaMethods, loading: getLoading }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(FormulaContext);
  const { activeStep, formulation, ingredientsBlend } = state || {};
  // local states
  const [isBlocker, setIsBlocker] = useState(true);
  const [autoSaveFormula, setAutoSaveFormula] = useState(false);

  const {
    handleSubmit: handleFormulaSubmit,
    formState: formulaFormState,
    getValues: getFormulaValues,
  } = formulaMethods;

  const { isDirty: isFormulaFormDirty } = formulaFormState;

  const { name: formulaNameInForm } = getFormulaValues();

  const { handleSubmit, formState, getValues } = methods;
  const { isDirty } = formState;

  const blocker = useCustomBlocker(isDirty, isFormulaFormDirty, isBlocker);

  const [duplicateFormula, { loading: duplicateLoading }] = useCreateFormulaMutation({
    onCompleted: (res) => {
      const { createFormula: generateFormula } = res || {};
      const { response } = generateFormula || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [saveFormulaAsDraft, { loading: saveFormulaAsLoading }] = useSaveFormulaAsDraftMutation({
    onCompleted: (res) => {
      const { saveFormulaAsDraft } = res || {};
      const { response } = saveFormulaAsDraft || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        if (autoSaveFormula) {
          blocker?.proceed && blocker?.proceed();
        } else {
          navigate(-1);
        }
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  // setting payload for formulaBlends
  const formulaBlends = ingredientsBlend?.map<FormulaBlendInput>((item) => {
    const { name: blendName, ingredients } = item;

    const ingredientBlends = formatCalcFormulaIngredients(ingredients);

    return {
      name: blendName,
      formulaIngredients: ingredientBlends,
    };
  });

  const onSubmitHandler = async (data: AddNewFormulaFormType, isDraft: boolean = false) => {
    const { formulaIngredients, name } = data;
    const {
      beadlets,
      bottleCap,
      coMfgCostType,
      coMfgCostPercentage,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      productSubType,
      productType,
      proteinBase,
      servingPerContainer,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      servingUnit,
      sandCoating,
      productSize,
    } = formulation;

    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

    if (isDraft) {
      await saveFormulaAsDraft({
        variables: {
          saveFormulaAsDraftInput: {
            name,

            servingUnit,
            bottleCap: bottleCap as BottleCapType,
            ...(coMfgCostType && { coMfgCostType: coMfgCostType as CoMfgCostType }),
            ...(coMfgCostPercentage && { coMfgCostPercentage: Number(coMfgCostPercentage) }),
            servingSize: Number(desiredServingSize),
            servingContainer: Number(servingPerContainer),

            productTypeId: productType,
            ...(productSubType && { subProductTypeId: productSubType }),
            productFormulationTypeId: productFormulationType?.value ?? '',

            ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
            ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

            ...(colorsHex?.length && { colorsHex: colorsHex }),

            ...(beadlets && { beadletId: beadlets }),

            ...(tabletType && { tabletTypeId: tabletType }),

            ...(productSize?.value && { productSizeId: productSize?.value }),

            ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

            ...(veganType && { veganType: veganType as VeganType }),
            ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
            ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
            ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

            ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
            ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

            ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
            ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

            ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
            ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
            ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

            ...(sandCoating && { sandCoatingId: sandCoating }),
            ...(tabletCoating && { tabletCoatingId: tabletCoating }),

            ...(capsuleType && { capsuleTypeId: capsuleType }),
            ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
            ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
          },
        },
      });
    } else {
      await duplicateFormula({
        variables: {
          createFormulaInput: {
            name,
            bottleCap: bottleCap as BottleCapType,
            ...(coMfgCostType && { coMfgCostType: coMfgCostType as CoMfgCostType }),
            ...(coMfgCostPercentage && { coMfgCostPercentage: Number(coMfgCostPercentage) }),
            servingUnit,
            servingSize: Number(desiredServingSize),
            servingContainer: Number(servingPerContainer),

            productTypeId: productType,
            ...(productSubType && { subProductTypeId: productSubType }),
            productFormulationTypeId: productFormulationType?.value ?? '',

            ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
            ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

            ...(colorsHex?.length && { colorsHex: colorsHex }),

            ...(productSize?.value && { productSizeId: productSize?.value }),

            ...(beadlets && { beadletId: beadlets }),

            ...(tabletType && { tabletTypeId: tabletType }),
            ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

            ...(veganType && { veganType: veganType as VeganType }),
            ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
            ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
            ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

            ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
            ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

            ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
            ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

            ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
            ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
            ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

            ...(sandCoating && { sandCoatingId: sandCoating }),
            ...(tabletCoating && { tabletCoatingId: tabletCoating }),

            ...(capsuleType && { capsuleTypeId: capsuleType }),
            ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
            ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
          },
        },
      });
    }
  };

  const onSave: SubmitHandler<AddNewFormulaFormType> = (data) => {
    onSubmitHandler(data);
    setIsBlocker(false);
  };

  const onSaveAsDraft: SubmitHandler<AddNewFormulaFormType> = async (data) => {
    onSubmitHandler(data, true);
    setIsBlocker(false);
  };

  const onSubmit: SubmitHandler<DosageFormulationFormType> = (data) => {
    dispatch({ type: ActionType.SET_FORMULATION, formulation: data });
    dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep + 1 });
  };

  const saveFormulaAsDraftAndContinue: SubmitHandler<SaveFormulaAsDraftFormType> = async (data) => {
    setAutoSaveFormula(true);
    const { name: formulaName } = data;
    const {
      beadlets,
      bottleCap,
      coMfgCostType,
      coMfgCostPercentage,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      proteinBase,
      servingPerContainer,
      servingUnit,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      productType,
      productSubType,
      productSize,
      sandCoating,
    } = getValues();

    const { formulaIngredients } = getFormulaValues();

    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

    await saveFormulaAsDraft({
      variables: {
        saveFormulaAsDraftInput: {
          name: formulaName,
          ...(productSize?.value && { productSizeId: productSize?.value }),

          ...(servingUnit && { servingUnit: servingUnit }),
          ...(desiredServingSize && { servingSize: Number(desiredServingSize) }),
          ...(servingPerContainer && { servingContainer: Number(servingPerContainer) }),

          ...(productType && { productTypeId: productType }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(productFormulationType?.value && { productFormulationTypeId: productFormulationType?.value }),

          ...(bottleCap && { bottleCap: bottleCap as BottleCapType }),
          ...(coMfgCostType && { coMfgCostType: coMfgCostType as CoMfgCostType }),
          ...(coMfgCostPercentage && { coMfgCostPercentage: Number(coMfgCostPercentage) }),
          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(beadlets && { beadletId: beadlets }),
          ...(tabletType && { tabletTypeId: tabletType }),
          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(veganType && { veganType: veganType as VeganType }),
          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
        },
      },
    });
  };

  const loading = getLoading || duplicateLoading || saveFormulaAsLoading;

  return (
    <Fragment>
      <DialogBox
        formulaName={formulaNameInForm}
        cancelNavigation={() => {
          setAutoSaveFormula(false);
          blocker?.reset && blocker?.reset();
        }}
        confirmNavigation={blocker?.proceed}
        showDialog={blocker.state === 'blocked'}
        saveAndLeaveNavigation={saveFormulaAsDraftAndContinue}
      />
      <BackdropLoader open={loading} text={getLoading ? FORMULA_FETCH_TEXT : FORMULA_IS_CREATING_TEXT} />
      <FormProvider {...methods}>
        <Box sx={flexCenterBetween}>
          <Typography variant="h5">{DUPLICATE_FORMULA_TEXT}</Typography>
          {activeStep === 1 ? (
            <Box display="flex" justifyContent="space-between" gap={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep - 1 });
                }}>
                {BACK_TEXT}
              </Button>
              <Box>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  disabled={loading}
                  onClick={handleFormulaSubmit(onSaveAsDraft)}>
                  {SAVE_AS_DRAFT_TEXT}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleFormulaSubmit(onSave)}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowIcon />}
              onClick={handleSubmit(onSubmit)}>
              {`Choose ${INGREDIENTS_TEXT}`}
            </Button>
          )}
        </Box>
        <StepForm formulaMethods={formulaMethods} activeStep={activeStep} />
      </FormProvider>
    </Fragment>
  );
};

export default DuplicateFormulaForm;
