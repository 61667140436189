import * as yup from 'yup';
import {
  decimalNumberMessage,
  invalidFloat4Message,
  invalidFloatMessage,
  maxMessage,
  maxNumberMessage,
  minMessage,
  minNumberMessage,
  overageInvalidMessage,
  percentageInvalidMessage,
  positiveMessage,
  positiveNumberMessage,
  potencyInvalidMessage,
  requiredMessage,
} from 'lib/helper';
import {
  EMAIL_REGEX,
  EMAIL_TEXT,
  FLOAT_3_REGEX,
  FLOAT_4_REGEX,
  FLOAT_REGEX,
  INVALID_EMAIL,
  PERCENTAGE_TEXT,
  POTENCY_TEXT,
  ZIP_CODE_TEXT,
} from 'constants/index';

export const selectorSchema = (label: string, isRequired: boolean = true) =>
  yup
    .object()
    .shape({
      name: yup.string(),
      value: yup.string(),
    })
    .test('', requiredMessage(label), ({ value, name }) => (isRequired ? !!value && !!name : true));

export const requiredInteger = (label: string, length: number, acceptZero = false) => {
  return yup
    .string()
    .required(requiredMessage(label))
    .test('', decimalNumberMessage(label), (val) => {
      if (val) {
        const num = parseFloat(val);
        if (num !== Math.floor(num)) {
          return false;
        } else if (!acceptZero && num <= 0) {
          return false;
        } else if (acceptZero && num < 0) {
          return false;
        }
      }
      return true;
    })
    .max(length, maxMessage(label, length));
};

export const requiredPositiveNumber = (label: string, length: number, acceptZero = false) => {
  return yup
    .string()
    .required(requiredMessage(label))
    .test('', positiveNumberMessage(label), (val) => {
      if (val) {
        const str = parseFloat(val);
        if (!acceptZero && str <= 0) {
          return false;
        } else if (acceptZero && str < 0) {
          return false;
        }
      }
      return true;
    })
    .max(length, maxMessage(label, length));
};

export const notRequiredFloat = (label: string, min: number = 0, max: number = 7) => {
  return yup.string().when({
    is: (val: string) => !!val,
    then: (schema) => schema.min(min, minMessage(label, min)).max(max, maxMessage(label, max)),
    otherwise: (schema) => schema.default(''),
  });
};

export const requiredFloat = (label: string, min: number = 0, max: number = 7) => {
  return yup
    .string()
    .required(requiredMessage(label))
    .min(min, minMessage(label, min))
    .max(max, maxMessage(label, max));
};

export const positiveNumberMax = (label: string, max = 10000) =>
  yup
    .string()
    .required(requiredMessage(label))
    .test('is-number', (val, { createError }) => {
      if (val) {
        const str = parseFloat(val);
        if (isNaN(str)) {
          return createError({ message: 'Invalid number' });
        }
        if (str < 0.01) {
          return createError({ message: minNumberMessage(label, 0.01) });
        }
        if (str > max) {
          return createError({ message: maxNumberMessage(label, max) });
        }
        return true;
      }
      return true;
    })
    .test('00.00', invalidFloatMessage(label), (value) => FLOAT_REGEX.test(value));

export const percentageRequired = (text = PERCENTAGE_TEXT) => {
  return yup
    .string()
    .required(requiredMessage(text))
    .test('0-100', percentageInvalidMessage(text), (value) => {
      const percentageVal = Number(value);
      if (percentageVal > 0 && percentageVal <= 100) {
        return true;
      }
      return false;
    })
    .test('00.00', invalidFloatMessage(text), (value) => FLOAT_REGEX.test(value));
};

export const notRequiredPercentage = (text = PERCENTAGE_TEXT) => {
  return yup.string().when({
    is: (val: string) => !!val,
    then: (schema) =>
      schema
        .required(requiredMessage(text))
        .test('0-100', percentageInvalidMessage(text), (value) => {
          const percentageVal = Number(value);
          if (percentageVal > 0 && percentageVal <= 100) {
            return true;
          }
          return false;
        })
        .test('00.00', invalidFloatMessage(text), (value) => FLOAT_REGEX.test(value)),
    otherwise: (schema) => schema.default(''),
  });
};

export const notRequiredMatches = (message: string, regex: RegExp) => {
  return yup.string().test('', message, (value) => (!value ? !value : regex.test(value)));
};

export const zipCodeSchema = (text = ZIP_CODE_TEXT) => {
  return yup.string().when({
    is: (val: string) => !!val,
    then: (schema) => schema.min(1, minMessage(text, 1)).max(12, maxMessage(text, 12)),
  });
};

export const potencySchema = (text = POTENCY_TEXT) => {
  return yup
    .string()
    .required(requiredMessage(text))
    .test('0-100', potencyInvalidMessage(text), (value) => {
      const potencyVal = Number(value);
      if (potencyVal > 0 && potencyVal <= 100) {
        return true;
      }
      return false;
    })
    .test('00.000', invalidFloatMessage(text), (value) => FLOAT_3_REGEX.test(value));
};

export const overageSchema = (text: string) => {
  return yup
    .string()
    .test('0-1', overageInvalidMessage(text), (value) => {
      if (value) {
        const overageVal = Number(value);
        if (overageVal > 0 && overageVal <= 1) {
          return true;
        }
        return false;
      }
      return true;
    })
    .test('00.0000', invalidFloat4Message(text), (value) => (value ? FLOAT_4_REGEX.test(value) : true));
};

export const nutrientInfoSchema = (text = POTENCY_TEXT) => {
  return yup.string().test('>0', positiveMessage(text), (value) => {
    if (value) {
      const potencyVal = Number(value);
      if (potencyVal) {
        return FLOAT_REGEX.test(value);
      }
      return false;
    }
    return true;
  });
};

export const notRequiredPhone = (message: string) => {
  return yup.string().test('phone', message, (value) => {
    return value ? value.length === 11 : !value;
  });
};

export const notRequiredPositiveNumber = (label: string, length: number, acceptZero = false) => {
  return yup
    .string()
    .test('', positiveNumberMessage(label), (val) => {
      if (val) {
        const str = parseFloat(val);
        if (!acceptZero && str <= 0) {
          return false;
        } else if (acceptZero && str < 0) {
          return false;
        }
      }
      return true;
    })
    .max(length, maxMessage(label, length));
};

export const isEmail = (isRequired = true) => {
  if (isRequired) {
    return yup
      .string()
      .trim()
      .required(requiredMessage(EMAIL_TEXT))
      .email(INVALID_EMAIL)
      .matches(EMAIL_REGEX, INVALID_EMAIL);
  }
  return yup
    .string()
    .trim()
    .when('isEmail', {
      is: (val: string) => val,
      then: (schema) =>
        schema.required(requiredMessage(EMAIL_TEXT)).email(INVALID_EMAIL).matches(EMAIL_REGEX, INVALID_EMAIL),
    });
};

export const requiredMatches = (label: string, message: string, regex: RegExp) => {
  return yup
    .string()
    .required(requiredMessage(label))
    .test('', message, (value) => regex.test(value || ''));
};
