import moment from 'moment';
import { Maybe } from 'yup';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import axios, { AxiosError } from 'axios';
import { ChipProps } from '@mui/material';
import { Country, State } from 'country-state-city';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
// component
import { Alert } from 'components/common/Alert';
// client, constants, graphql
import {
  SelectType,
  ProductTypeSelect,
  CarrierSelectType,
  IngredientItemType,
  UpdateAttachmentInput,
  CreateAttachmentInput,
  IngredientItemFormType,
  AddNewIngredientFormType,
  AddBottleCapacityFormType,
  RadioGroupControllerExtendedProps,
  SubComponentSelectType,
  AdditionalContactSelectType,
} from 'interfaces';
import {
  UserRoles,
  Ingredient,
  ProductTypes,
  FormulaStatus,
  SupplierStatus,
  BeadletsPayload,
  AllergensPayload,
  SuppliersPayload,
  SweetenersPayload,
  AttachmentPayload,
  CategoriesPayload,
  FormulaIngredient,
  ExcipientsPayload,
  IngredientsPayload,
  ProductSizesPayload,
  SandCoatingsPayload,
  ServingSizesPayload,
  ProductTypesPayload,
  CapsuleTypesPayload,
  ExcipientTypesPayload,
  TabletCoatingsPayload,
  FormulaIngredientType,
  FormulaIngredientUnit,
  SubProductTypesPayload,
  FormulaIngredientInput,
  ExcipientLabelsPayload,
  IngredientCarrierInput,
  ProductFormulationTypes,
  ProductExcipientSupplier,
  CreateBottleCapacityInput,
  CreateIngredientInfoInput,
  ProductIngredientsPayload,
  DraftFormulaIngredientInput,
  IngredientSubComponentInput,
  BottleSizeProductTypesPayload,
  ProductFormulationTypesPayload,
  SandComponentsPayload,
  CreateIngredientInput,
  IngredientSupplierInput,
  SupplierType,
  FormulaBlend,
} from 'generated/graphql';
import client from 'apollo';
import { AUTH_LINKS, FORMULA_INGREDIENT_TYPE_MAPPED, HTTP_STATUS, TOKEN } from 'constants/index';

export const getToken = () => localStorage.getItem(TOKEN) || '';
export const clearToken = () => localStorage.removeItem(TOKEN);

export const handleLogout = () => {
  clearToken();
  client.clearStore();
  window.location.href = AUTH_LINKS.LOGIN_LINK;
};

// validationSchema Messages
export const invalidMessage = (fieldName: string) => `${fieldName} is invalid`;
export const requiredMessage = (fieldName: string): string => `${fieldName} is required.`;
export const minMessage = (name: string, length: number): string => `${name} must be ${length} characters.`;
export const maxMessage = (name: string, length: number): string =>
  `${name} should not be more than ${length} Characters.`;
export const minNumberMessage = (name: string, length: number): string =>
  `${name} must be at least ${length}.`;
export const maxNumberMessage = (name: string, length: number): string =>
  `${name} should not be more than ${length}.`;
export const invalidFloatMessage = (fieldName: string): string => `${fieldName} should be in 00.00 format`;
export const invalidFloat3Message = (fieldName: string): string => `${fieldName} should be in 00.000 format`;
export const invalidFloat4Message = (fieldName: string): string => `${fieldName} should be in 00.0000 format`;
export const minFieldMessage = (name: string, length: number): string =>
  `${name} field must have at least ${length} item`;
export const maxFieldMessage = (name: string, length: number): string =>
  `${name} field should not be more than ${length} items`;
export const potencyInvalidMessage = (fieldName: string) =>
  `${fieldName} Value should greater than 0  but less than or equal to 100 (0 < ${fieldName} <= 100)`;
export const overageInvalidMessage = (fieldName: string) =>
  `${fieldName} Value should greater than 0  but less than or equal to 1 (0 < ${fieldName} <= 1)`;
export const invalidPhoneMessage = (phone: string) => `${phone} must be in US format`;
export const positiveNumberMessage = (label: string) => `${label} should be a positive value`;
export const decimalNumberMessage = (label: string) => `${label} should not be a decimal value`;
export const percentageInvalidMessage = (fieldName: string) =>
  `${fieldName} Value should greater than 0  but less than or equal to 100 (0 < ${fieldName} <= 100)`;

export const positiveMessage = (label: string) => `${label} should be a positive value and format (00.00)`;

export const isSuperAdmin = (userRoles: UserRoles[]) =>
  userRoles?.some((val) => val === UserRoles.SuperAdmin);

export const isAdmin = (userRoles: UserRoles[]) => userRoles?.some((val) => val === UserRoles.Admin);
export const isStaff = (userRoles: UserRoles[]) => userRoles?.some((val) => val === UserRoles.Staff);

export const isAdminOrSuperAdmin = (userRoles: UserRoles[]) =>
  userRoles?.some((val) => val === UserRoles.Admin || val === UserRoles.SuperAdmin);

export const isAdminOrStaff = (userRoles: UserRoles[]) =>
  userRoles?.some((val) => val === UserRoles.Admin || val === UserRoles.Staff);

export const isAdminOrSuperAdminOrStaff = (userRoles: UserRoles[]) =>
  userRoles?.some((val) => val === UserRoles.Admin || val === UserRoles.SuperAdmin || UserRoles.Staff);

export const formatTimeStamp = (date: string, format?: string): string =>
  moment(parseInt(date, 10)).format(format || 'MM/DD/YYYY');

export const formatMomentStamp = (date?: string, format = 'MM/DD/YYYY'): string =>
  moment(date || new Date()).format(format);

export const formatPhone = (phone: string): string =>
  phone
    ? `+${phone[0]} (${phone.substring(1, 4)})  ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
    : '--';

export const formatValue = (value: string) => {
  let formatted = '';

  value
    ?.split('_')
    ?.map(
      (term) =>
        (formatted = `${formatted} ${term?.charAt(0)?.toUpperCase()}${term?.slice(1)?.toLowerCase()}`),
    );

  return formatted?.trim();
};

export const formatTextType = (type: string): string => {
  const words: string[] = type.split('_');
  return words?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

export const getFormulaStatusColor = (status: FormulaStatus): ChipProps['color'] => {
  switch (status) {
    case FormulaStatus.Active:
      return 'success';
    case FormulaStatus.Inactive:
      return 'error';
    default:
      return 'info';
  }
};

export const generateRandomPassword = () => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numericChars = '0123456789';
  const specialChars = '!@#$%^&*()_-+=<>?';

  let password = '';

  // Generate one character from each category
  const randomUppercase = uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  const randomLowercase = lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
  const randomNumeric = numericChars[Math.floor(Math.random() * numericChars.length)];
  const randomSpecialChar = specialChars[Math.floor(Math.random() * specialChars.length)];

  password += randomUppercase + randomLowercase + randomNumeric + randomSpecialChar;

  // Generate remaining random characters
  const remainingChars = 12 - password.length;
  for (let i = 0; i < remainingChars; i++) {
    const randomChar = String.fromCharCode(Math.floor(Math.random() * 94) + 33); // ASCII printable characters range
    password += randomChar;
  }
  return password;
};

export function createEnumMappedArray<T>(
  enumType: T,
  isCapitalize = true,
  isLowerCase = false,
): SelectType[] {
  return Object.values(enumType as object).map((enumValue) => ({
    name: isCapitalize ? formatTextType(enumValue) : isLowerCase ? enumValue?.toLowerCase() : enumValue,
    value: enumValue,
  }));
}

export const renderCategories = (categories: CategoriesPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!categories) {
    for (let item of categories) {
      if (item) {
        const { id: value, name, description } = item;

        data.push({
          value,
          name: description ? `${name || ''} : ${description}` : name || '',
        });
      }
    }
  }

  return data;
};

export const renderSuppliers = (suppliers: SuppliersPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!suppliers) {
    for (let item of suppliers) {
      if (item) {
        const { id: value, name, description, status } = item;

        data.push({
          value,
          name: description ? `${name || ''} : ${description}` : name || '',
          isActive: status === SupplierStatus.Active ? true : false,
        });
      }
    }
  }

  return data;
};

export const renderProductIngredients = (ingredients: ProductIngredientsPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!ingredients) {
    for (let item of ingredients) {
      if (item) {
        const { id: value, name, isActive } = item;

        data.push({
          value,
          name: name || '',
          isActive: !!isActive,
        });
      }
    }
  }

  return data;
};

export const renderIngredients = (suppliers: IngredientsPayload['data']): IngredientItemFormType[] => {
  const data: IngredientItemFormType[] = [];

  if (!!suppliers) {
    for (let item of suppliers) {
      if (item) {
        const { id: value, name, potency, isActive } = item;

        data.push({
          value,
          name: name || '',
          potency: parseFloat(potency ? potency : '1'),
          ingredientPotency: parseFloat(potency ? potency : '1'),
          isActive: isActive || false,
        });
      }
    }
  }

  return data;
};

export const renderAllergens = (allergens: AllergensPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!allergens) {
    for (let item of allergens) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: formatValue(name || ''),
        });
      }
    }
  }

  return data;
};

export const renderProductTypes = (products: ProductTypesPayload['data']): ProductTypeSelect[] => {
  const data: ProductTypeSelect[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name, type } = item;

        data.push({
          value,
          name: name || '',
          type: type || '',
        });
      }
    }
  }

  return data;
};

export const renderExcipientLabel = (products: ExcipientLabelsPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name || '',
        });
      }
    }
  }

  return data;
};

export const renderExcipient = (products: ExcipientsPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name || '',
        });
      }
    }
  }

  return data;
};

export const renderProductSubTypes = (products: SubProductTypesPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name, isActive } = item;

        data.push({
          value,
          isActive,
          name: isActive ? name || '' : `${name ?? ''} (Coming Soon)`,
        });
      }
    }
  }

  return data;
};

export const renderProductFormulationTypes = (
  products: ProductFormulationTypesPayload['data'],
): ProductTypeSelect[] => {
  const data: ProductTypeSelect[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name, type } = item;

        data.push({
          value,
          name: name || '',
          type: type || '',
        });
      }
    }
  }

  return data;
};

export const renderProductSizes = (products: ProductSizesPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!products) {
    for (let item of products) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name || '',
        });
      }
    }
  }

  return data;
};

export const renderServingSize = (bottleSizes: ServingSizesPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!bottleSizes) {
    for (let item of bottleSizes) {
      if (item) {
        const { id: value, size, isActive } = item;

        data.push({
          value,
          name: `${size}` || '',
          isActive: isActive || false,
        });
      }
    }
  }

  return data;
};

export const renderCapsuleTypes = (capsuleTypes: CapsuleTypesPayload['data']): SelectType[] => {
  const data: SelectType[] = [];

  if (!!capsuleTypes) {
    for (let item of capsuleTypes) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name || '',
        });
      }
    }
  }

  return data;
};

export const renderExcipientType = (
  excipientType: ExcipientTypesPayload['data'],
): RadioGroupControllerExtendedProps[] => {
  const data: RadioGroupControllerExtendedProps[] = [];
  if (excipientType) {
    for (let item of excipientType) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name ?? '',
        });
      }
    }
  }
  return data;
};

export const renderTabletCoating = (
  tabletCoating: TabletCoatingsPayload['data'],
): RadioGroupControllerExtendedProps[] => {
  const data: RadioGroupControllerExtendedProps[] = [];
  if (tabletCoating) {
    for (let item of tabletCoating) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name ?? '',
        });
      }
    }
  }
  return data;
};

export const renderSandCoating = (tabletCoating: SandCoatingsPayload['data']): SelectType[] => {
  const data: SelectType[] = [];
  if (tabletCoating) {
    for (let item of tabletCoating) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name ?? '',
        });
      }
    }
  }
  return data;
};

export const renderSandComponent = (tabletCoating: SandComponentsPayload['data']): SelectType[] => {
  const data: SelectType[] = [];
  if (tabletCoating) {
    for (let item of tabletCoating) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name ?? '',
        });
      }
    }
  }
  return data;
};

export const renderSweetener = (
  sweetener: SweetenersPayload['data'],
): RadioGroupControllerExtendedProps[] => {
  const data: RadioGroupControllerExtendedProps[] = [];
  if (sweetener) {
    for (let item of sweetener) {
      if (item) {
        const { id: value, name, size } = item;

        data.push({
          value,
          name: size ? `${name || ''} : ${size}` : name || '',
        });
      }
    }
  }
  return data;
};

export const renderBeadlets = (beadlets: BeadletsPayload['data']): RadioGroupControllerExtendedProps[] => {
  const data: RadioGroupControllerExtendedProps[] = [];
  if (beadlets) {
    for (let item of beadlets) {
      if (item) {
        const { id: value, name } = item;

        data.push({
          value,
          name: name ?? '',
        });
      }
    }
  }
  return data;
};

export const formatCalcFormulaIngredients = (ingredients: IngredientItemType[]): FormulaIngredientInput[] =>
  ingredients?.map<FormulaIngredientInput>(({ ingredientId, potency, unit, value, type }) => ({
    unit,
    ingredientId,
    potency: Number(potency),
    value: value ? Number(value) : 1,
    type: type ? (type as FormulaIngredientType) : FormulaIngredientType.Tablet,
  }));

export const formatCalcFormulaIngredientsWithOptionalType = (
  ingredients: IngredientItemType[],
): DraftFormulaIngredientInput[] =>
  ingredients?.map<DraftFormulaIngredientInput>(({ ingredientId, potency, unit, value, type }) => ({
    unit,
    ingredientId,
    potency: Number(potency),
    value: value ? Number(value) : 1,
    ...(type && { type: type as FormulaIngredientType }),
  }));

export const formatFormulaIngredients = (ingredients: FormulaIngredient[]): IngredientItemType[] =>
  ingredients?.map<IngredientItemType>((item): IngredientItemType => {
    const { ingredient, ingredientId, type, unit, value, potency } = item || {};
    const { name, potency: ingredientPotency, isActive } = ingredient || {};
    return {
      ingredientId: ingredientId ?? '',
      name: name ?? '',
      potency: potency ?? 1,
      ingredientPotency: parseFloat(ingredientPotency ?? '1'),
      type: type ? (type as FormulaIngredientType) : '',
      unit: (unit as FormulaIngredientUnit) ?? '',
      value: `${value ?? ''}`,
      isActive: isActive ?? false,
    };
  }) || [];

export const modifyFormulaIngredient = (formulaIngredients: IngredientItemType[]) => {
  return formulaIngredients?.map((ingredient) => {
    return { ...ingredient, type: '' };
  });
};

export const downloadSheet = (attachmentUrl: string, csv = false) => {
  const a = document.createElement('a');
  a.download = csv ? 'export_formula_csv' : 'fact_sheet';
  a.target = '_blank';
  a.href = attachmentUrl;
  a.click();
};

const getCapsuleType = (key: ProductFormulationTypes): SelectType[] => {
  switch (key) {
    case ProductFormulationTypes.LiquidFilled:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Capsule);

    case ProductFormulationTypes.PowderFilled:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Capsule);

    case ProductFormulationTypes.CapInCap:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.InnerCapsule ||
          item?.value === FormulaIngredientType.OuterCapsule,
      );

    case ProductFormulationTypes.LiquidFilledWithBeadlets:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.Capsule || item?.value === FormulaIngredientType.Beadlet,
      );

    case ProductFormulationTypes.LiquidFilledWithTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.Capsule || item?.value === FormulaIngredientType.Tablet,
      );

    case ProductFormulationTypes.LiquidFilledWithMiniTabs:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.Capsule || item?.value === FormulaIngredientType.Tablet,
      );

    default:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Tablet);
  }
};

const getTabletType = (key: ProductFormulationTypes): SelectType[] => {
  switch (key) {
    case ProductFormulationTypes.SingleLayerTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED?.filter((item) => item?.value === FormulaIngredientType.Tablet);

    case ProductFormulationTypes.ChewableTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED?.filter((item) => item?.value === FormulaIngredientType.Tablet);

    case ProductFormulationTypes.MiniTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED?.filter((item) => item?.value === FormulaIngredientType.Tablet);

    case ProductFormulationTypes.EffervescentTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED?.filter((item) => item?.value === FormulaIngredientType.Tablet);

    case ProductFormulationTypes.BiLayeredTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.Layer_1 || item?.value === FormulaIngredientType.Layer_2,
      );

    case ProductFormulationTypes.TriLayerTablet:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter(
        (item) =>
          item?.value === FormulaIngredientType.Layer_1 ||
          item?.value === FormulaIngredientType.Layer_2 ||
          item?.value === FormulaIngredientType.Layer_3,
      );

    default:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Tablet);
  }
};

export const getType = (type: ProductTypes, formulationType: ProductFormulationTypes): SelectType[] => {
  switch (type) {
    case ProductTypes.Tablet:
      return getTabletType(formulationType);

    case ProductTypes.Capsule:
      return getCapsuleType(formulationType);

    case ProductTypes.Gummies:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Gummy);

    case ProductTypes.Powder:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Powder);

    default:
      return FORMULA_INGREDIENT_TYPE_MAPPED.filter((item) => item?.value === FormulaIngredientType.Powder);
  }
};

export const attachmentUpload = async (
  file: File,
  input: CreateAttachmentInput,
  showSuccessMsg: boolean = false,
) => {
  const token = getToken();
  try {
    if (!token) {
      throw new Error('Unauthorized');
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/attachment/upload`;
    const { title, type, typeId } = input;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title?.toLocaleLowerCase());
    formData.append('type', type?.toLocaleLowerCase());
    formData.append('typeId', typeId);
    const response = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data, status } = response || {};
    if (status === HTTP_STATUS.CREATED) {
      const { response, attachment } = data as unknown as AttachmentPayload;
      const { status, message } = response || {};
      const { id } = attachment || {};
      if (status === HTTP_STATUS.CREATED && id) {
        showSuccessMsg && Alert.success(message ?? '');
        return attachment;
      } else {
        Alert.warning(message ?? '');
      }
    } else {
      Alert.error('Attachment is not created');
    }
  } catch (error) {
    const { message } = error as AxiosError;
    Alert.error(message as string);
  }
};

export const attachmentUpdate = async (
  file: File,
  input: UpdateAttachmentInput,
  showSuccessMsg: boolean = false,
) => {
  const token = getToken();
  try {
    if (!token) {
      throw new Error('Unauthorized');
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/attachment/update`;
    const { title, type, typeId, id } = input;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', file);
    formData.append('type', type?.toLocaleLowerCase());
    formData.append('title', title?.toLocaleLowerCase());
    formData.append('typeId', typeId);
    const response = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data, status } = response || {};
    if (status === HTTP_STATUS.CREATED) {
      const { response } = data as unknown as AttachmentPayload;
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        showSuccessMsg && Alert.success(message ?? '');
      } else {
        Alert.warning(message ?? '');
      }
    } else {
      Alert.error('Attachment is not updated');
    }
  } catch (error) {
    const { message } = error as AxiosError;
    Alert.error(message as string);
  }
};

export const getUpto2Decimal = (num: number, decimal = 2): number => {
  return !Number.isNaN(num) ? parseFloat(num?.toFixed(decimal)) : 0;
};

export const getPotencyValues = (val: number): SelectType[] => {
  const potency = Number(val);
  if (Number.isNaN(potency)) {
    return [
      {
        name: '100',
        value: '1',
      },
    ];
  }
  if (potency !== 1) {
    return [
      {
        name: '100',
        value: '1',
      },

      {
        name: `${getUpto2Decimal((potency ?? 1) * 100, 3)}`,
        value: `${getUpto2Decimal(potency ?? 1, 3)}`,
      },
    ];
  } else {
    return [
      {
        name: '100',
        value: '1',
      },
    ];
  }
};

export const splitStringIntoArrayOfString = (inputValue: string) => {
  // Split the input string into an array of strings
  return inputValue?.split(',')?.map((item) => item);
};

export const formatToLowerCase = (value: string) => {
  return value?.toLowerCase();
};

export const getFillPercentage = (grams: number, weight: number, servingContainer: number): number => {
  return getUpto2Decimal(((weight * servingContainer) / grams) * 100, 0);
};

export const convertEditorStateToHtml = (state: EditorState): string => {
  return draftToHtml(convertToRaw(state.getCurrentContent()));
};

export const convertHtmlToText = (text: string): string => {
  return text?.replace(/<[^>]*>/g, '');
};

export const convertHtmlToEditorState = (html: string): EditorState => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
};

export const formatCarriers = (carriers: CarrierSelectType[]): IngredientCarrierInput[] => {
  return carriers?.map((carrier) => {
    const { excipient, percentage } = carrier || {};
    const { value } = excipient || {};
    return { carrierId: value, percentage: Number(percentage) };
  });
};

export const formatSubComponents = (
  subComponents: SubComponentSelectType[],
): IngredientSubComponentInput[] => {
  return subComponents?.map((item) => {
    const { name, percentage } = item || {};
    return { name, percentage: Number(percentage) };
  });
};

export const formatIngredientSubComponents = (
  ingredientSubComponents: Ingredient['ingredientSubComponents'],
): SubComponentSelectType[] => {
  if (ingredientSubComponents?.length) {
    return ingredientSubComponents?.map<SubComponentSelectType>((item) => {
      const { name, percentage } = item || {};
      return {
        name: name || '',
        percentage: `${percentage || ''}`,
      };
    });
  }
  return [];
};

export const formatIngredientCarriers = (
  ingredientCarriers: Ingredient['ingredientCarriers'],
): CarrierSelectType[] => {
  if (ingredientCarriers?.length) {
    return ingredientCarriers?.map<CarrierSelectType>((item) => {
      const { carrier, percentage } = item || {};
      const { name, id } = carrier || {};

      return {
        excipient: {
          name: name || '',
          value: id || '',
        },
        percentage: `${percentage || ''}`,
      };
    });
  }
  return [];
};
export const formatIngredientInfo = (data: AddNewIngredientFormType): CreateIngredientInfoInput => {
  const { ingredientInfo, lead, cadmium, arsenic, mercury } = data || {};

  const {
    addedSugar,
    biotin,
    boron,
    calcium,
    calories,
    carbohydrates,
    cholesterol,
    choline,
    dietaryFiber,
    folate,
    iodine,
    iron,
    magnesium,
    moisture,
    monounsaturatedFat,
    niacin,
    organic,
    polyunsaturatedFat,
    potassium,
    protein,
    riboflavin,
    saturatedFat,
    sodium,
    thiamin,
    totalFat,
    totalSugars,
    transFat,
    vitaminA,
    vitaminB12,
    vitaminB6,
    vitaminC,
    vitaminD,
    vitaminE,
    vitaminK2,
    zinc,
    chloride,
    chromium,
    copper,
    manganese,
    molybdenum,
    pantothenicAcid,
    phosphorus,
    selenium,
  } = ingredientInfo || {};

  return {
    zinc: zinc,
    iron: iron,
    lead: lead,
    boron: boron,
    iodine: iodine,
    biotin: biotin,
    folate: folate,
    sodium: sodium,
    niacin: niacin,
    arsenic: arsenic,
    cadmium: cadmium,
    calcium: calcium,
    choline: choline,
    mercury: mercury,
    organic: organic,
    protein: protein,
    thiamin: thiamin,
    calories: calories,
    moisture: moisture,
    totalFat: totalFat,
    transFat: transFat,
    vitaminA: vitaminA,
    vitaminC: vitaminC,
    vitaminD: vitaminD,
    vitaminE: vitaminE,
    magnesium: magnesium,
    potassium: potassium,
    vitaminB6: vitaminB6,
    vitaminK2: vitaminK2,
    addedSugar: addedSugar,
    vitaminB12: vitaminB12,
    riboflavin: riboflavin,
    copper: Number(copper),
    totalSugars: totalSugars,
    cholesterol: cholesterol,
    dietaryFiber: dietaryFiber,
    saturatedFat: saturatedFat,
    chromium: Number(chromium),
    selenium: Number(selenium),
    chloride: Number(chloride),
    carbohydrates: carbohydrates,
    manganese: Number(manganese),
    phosphorus: Number(phosphorus),
    molybdenum: Number(molybdenum),
    monounsaturatedFat: monounsaturatedFat,
    polyunsaturatedFat: polyunsaturatedFat,
    pantothenicAcid: Number(pantothenicAcid),
  };
};

const countries = Country?.getAllCountries();
const data = countries?.map<SelectType>(({ name, isoCode }) => ({
  name,
  value: isoCode,
}));

export const countriesToSelectType = (searchQuery: string): SelectType[] => {
  return data?.filter((country) => country?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
};

export const stateToSelectType = (searchQuery: string, countryCode: string): SelectType[] => {
  const stateData = State.getStatesOfCountry(countryCode);
  const stateSelector = stateData?.map<SelectType>(({ name }) => ({
    name,
    value: name,
  }));

  return stateSelector?.filter((state) => state?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
};

export const formatBottleSizeProductTypes = (
  bottleSizeProductTypes: BottleSizeProductTypesPayload['data'],
): string => {
  if (bottleSizeProductTypes) {
    return bottleSizeProductTypes?.reduce<string>((prev, bottleSizeProductType) => {
      const { productType } = bottleSizeProductType || {};
      const { name } = productType || {};

      prev = name ? `${prev}${prev ? ', ' : ''}${name}` : prev;

      return prev;
    }, '');
  }
  return '';
};

export const suppliersIds = (suppliers: SelectType[]): string[] => {
  return (
    suppliers?.map((item) => {
      return item?.value;
    }) || []
  );
};

export const suppliers = (
  productExcipientSupplier: Maybe<Maybe<ProductExcipientSupplier>[]> | undefined,
): SelectType[] => {
  return (
    productExcipientSupplier?.map((item) => {
      return { name: item?.supplier?.name || '', value: item?.supplier?.id || '' };
    }) || []
  );
};

export const formatAdditionalContact = (
  additionalContacts: AdditionalContactSelectType[],
): AdditionalContactSelectType[] => {
  if (additionalContacts?.length) {
    return additionalContacts?.map<AdditionalContactSelectType>((item) => {
      const { name, email } = item || {};
      return {
        name: name || '',
        email: email || '',
      };
    });
  }
  return [];
};
export const createBottleCapacityInput = (data: AddBottleCapacityFormType): CreateBottleCapacityInput => {
  const { fillingPercentage, lohCost, bottleSize, productSize, productType, servingSize } = data || {};
  const { value: bottleSizeId } = bottleSize || {};
  const { value: productSizeId } = productSize || {};
  const { value: productTypeId, type } = productType || {};
  const { value: servingSizeId } = servingSize || {};

  return {
    lohCost: parseFloat(lohCost) || 0,
    bottleSizeId: bottleSizeId || '',
    productSizeId: productSizeId || '',
    productTypeId: productTypeId || '',
    fillPercentage: type === ProductTypes.Powder ? 0 : parseFloat(fillingPercentage) || 0,
    servingSizeId: type === ProductTypes.Powder ? null : servingSizeId ? servingSizeId : null,
  };
};

export const getLastFiveYears = (): SelectType[] => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 5 }, (_, i) => {
    const val = `${currentYear - i}`;
    return { name: val, value: val };
  });
};

export const handleLongWord = (word: string): string[] => {
  if (word.length > 8) {
    const middle = Math.floor(word.length / 3);
    const parts =
      word.length === 1
        ? [word]
        : [word.slice(0, middle), word.slice(middle, middle * 2), word.slice(middle * 2)];
    return parts;
  }
  return [word];
};

export const formatIngredientInput = (data: AddNewIngredientFormType): CreateIngredientInput => {
  const {
    type,
    name,
    cost,
    amount,
    potency,
    category,
    shelfLife,
    latinName,
    productTypes,
    subCategory,
    bulkDensity,
    lossOnDrying,
    originCountry,
    tappedDensity,
    tradeMarkName,
    scientificName,
    suggestedOverage,
    averageParticleSize,
    manufacturingCountry,
    allergens,
    primarySupplier,
    secondarySupplier,
    tertiarySupplier,
    carriers,
    subComponents,
    gummyOverage,

    nutrients,
    nutrientName,
    showB3,
  } = data || {};

  const potencyInDecimal = Number(potency) / 100;

  const { value: primarySupplierId } = primarySupplier;
  const { value: secondarySupplierId } = secondarySupplier;
  const { value: tertiarySupplierId } = tertiarySupplier;

  const suppliersList: IngredientSupplierInput[] = [];
  if (primarySupplierId) {
    suppliersList.push({ type: SupplierType.Primary, supplier: primarySupplierId });
  }

  if (secondarySupplierId) {
    suppliersList.push({ type: SupplierType.Secondary, supplier: secondarySupplierId });
  }

  if (tertiarySupplierId) {
    suppliersList.push({ type: SupplierType.Tertiary, supplier: tertiarySupplierId });
  }

  const { value: categoryId } = category || {};
  const { value: subCategoryId } = subCategory || {};
  const { value: originCountryId } = originCountry || {};
  const { value: manufacturingCountryId } = manufacturingCountry || {};

  const ingredientInfoObject = formatIngredientInfo(data);
  const carriersList = formatCarriers(carriers);
  const subComponentsList = formatSubComponents(subComponents);
  const labelName = convertEditorStateToHtml(latinName);

  return {
    type,
    name,
    latinName: labelName,
    bulkDensity,
    productTypes,
    lossOnDrying,
    tappedDensity,
    tradeMarkName,
    scientificName,
    cost: parseFloat(cost),
    categoryId: categoryId,
    amount: parseFloat(amount),
    subCategoryId: subCategoryId,
    potency: `${potencyInDecimal}`,
    shelfLife: parseFloat(shelfLife),
    ingredientAllergens: allergens,
    originCountry: originCountryId,
    ingredientSuppliers: suppliersList,
    ingredientInfo: ingredientInfoObject,
    avergageParticleSize: averageParticleSize,
    manufacturingCountry: manufacturingCountryId,
    gummyOverage: parseFloat(gummyOverage),
    suggestedOverage: parseFloat(suggestedOverage),
    carriers: carriersList,
    subComponents: subComponentsList,
    nutrients,
    nutrientName,
    showB3,
  };
};

export const blendIngredientFetch = (
  formulaBlends: Maybe<Maybe<FormulaBlend>[]>,
  formulaIngredients: Maybe<Maybe<FormulaIngredient>[]>,
): FormulaIngredient[] => {
  const blendIngredients = formulaBlends?.flatMap((blend) => blend?.formulaIngredients) ?? [];
  const formulaIng = [...(formulaIngredients ?? []), ...(blendIngredients ?? [])].filter(
    (ingredient): ingredient is NonNullable<typeof ingredient> => ingredient !== undefined,
  );
  return formulaIng;
};
